import { Component, OnInit } from '@angular/core';
import { SessionsStoreUserInformationService } from 'src/app/services/sessions-store-user-information.service';

@Component({
  selector: 'app-manage-funding-data',
  templateUrl: './manage-funding-data.component.html',
  styleUrls: ['./manage-funding-data.component.scss']
})
export class ManageFundingDataComponent implements OnInit {
  showAdminLink: boolean = false;
  showFundingDataLink: boolean = false;
  showAgreementLink: boolean = false;
  constructor(private sessionsStoreUserInformationService: SessionsStoreUserInformationService) { }

  ngOnInit(): void {
    this.showAdminLink = this.sessionsStoreUserInformationService.isSysAdmin();
    this.showFundingDataLink = this.sessionsStoreUserInformationService.isFundingDataProcessAccess() ||
    this.sessionsStoreUserInformationService.isFundingDataPublishAccess();
    this.showAgreementLink = this.sessionsStoreUserInformationService.isFundingAgreementDataAccess();
  }

}
