import { HttpClient } from "@angular/common/http";
import { Injectable, Inject, Optional } from "@angular/core";
import { Observable } from "rxjs";
import { BannerStateCommand } from "src/app/layout/components/banner/state/banner-state.command";
import { IFundingStreamScope } from "src/app/models/funding-flag-scope";
import { CustomApiService, API_BASE_URL } from "src/app/services/api/custom-api.service";
import { IProviderSnapshotPagingRequest } from "src/app/share-data/models/provider-snapshot";
import { IFundingDataSchema, IFundingDataSchemaPagingRequest, IFundingDataSchemaPagingResponse } from "../../models/funding-data-schema.model";
import { retry } from "rxjs/operators";
import { IFundingDataDefinitioPagingRequest, IFundingDataDefinitionPagingResponse } from "../../models/funding-data-definition.model";
import { IFilterDataModel, IFundingDataRemoveModel, IFundingDataStagingModel, IFundingDataStagingRequest, IFundingDataStagingResponse, IFundingDataStagingTransactionInreviewResponse, IFundingDataStagingTransactionResponse, IFundingTransactionModel, ISchemaStagingModel } from "../../models/funding-data-staging.model";
import { FundingDataSession } from "../../models/FundingDataSession.model";
import { IFundingDataVersionRequest } from "../../models/funding-data-snapshotHistory-model";
import { FundingDataStagingTransaction } from "../../models/FundingDataStagingTransaction.model";
import { ProviderHistoryQuery } from "src/app/services/api/provider-audit-api.service";
import { IProviderHistoryEvent } from "src/app/maintain-data/components/providers/history-tab/history-tab-model";
import { FundingDataApprove, WaitingForApproval } from "../../models/FundingDataApprove.model";
import { ReferenceName } from "../../models/FundingDataSQLData.model";
import { ISchemaAuditLogResponse } from "../../models/schema-audit-log.model";
import { ISnapshotDetailsModel } from "../../models/funding-data-schema-model";
import { FundingDataSnapshotTransactionViewModel } from "../../models/FundingDataSnapshotTransaction.model";
import { FundingDataChangeNote } from "../../models/FundingDataChangeNote.model";


@Injectable({
  providedIn: 'root',
})
export class FundingDataApiService extends CustomApiService {

  private httpClient: HttpClient;
  private httpBaseUrl: string;

  constructor(
    @Inject(HttpClient) http: HttpClient, @Inject(BannerStateCommand) bannerStateCommand,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string
  ) {
    super(http, bannerStateCommand, baseUrl);
    this.httpClient = http;
    this.httpBaseUrl = baseUrl;
  }

  getFilterOptions(pageRequest: IProviderSnapshotPagingRequest): Observable<IFundingStreamScope[]> {
    return this.post<IFundingStreamScope[]>('/api/fundingDataSchema/fds/fundingstream/configuration', pageRequest);
  }

  getAllFundingDataSchemas(pageRequest: IFundingDataSchemaPagingRequest): Observable<IFundingDataSchemaPagingResponse> {
    return this.post<IFundingDataSchemaPagingResponse>('/api/fundingDataSchema/fds/query', pageRequest)
      .pipe(retry(3));
  }

  public getFundingDataSchemaById(schemaId: string): Observable<IFundingDataSchema> {
    return this.get<IFundingDataSchema>('/api/fundingDataSchema/fds/getFundingDataSchemaById', null, { schemaId: schemaId })
      .pipe(retry(3));
  }

  getAllFundingDataDatadefinitions(pageRequest: IFundingDataDefinitioPagingRequest): Observable<IFundingDataDefinitionPagingResponse> {
    return this.post<IFundingDataDefinitionPagingResponse>('/api/fundingDataDefinition/fds/definition', pageRequest)
      .pipe(retry(3));
  }

  public getFundingDataStaging(fundingData: IFundingDataStagingRequest): Observable<IFundingDataStagingResponse> {
    return this.post<IFundingDataStagingResponse>('/api/FundingData/getStagingData', fundingData, null, null, false);
  }

  public getFundingDataActiveSessions(userId: number): Observable<FundingDataSession> {
    return this.get<FundingDataSession>('/api/FundingData/getActiveSessions', null, { userId: userId });
  }

  public getFundingDataActiveSessionsQuery(pageRequest: IFundingDataVersionRequest): Observable<IFundingDataStagingTransactionInreviewResponse> {
    return this.post<IFundingDataStagingTransactionInreviewResponse>('/api/FundingData/stagingData/query', pageRequest);
  }

  //AuditLog/query
  public getSchemaAuditLog(pageRequest: IFundingDataVersionRequest): Observable<ISchemaAuditLogResponse> {
    return this.post<ISchemaAuditLogResponse>('/api/SchemaAuditLog/AuditLog/query', pageRequest);
  }

  public getFundingDataActiveSessionsReviewQuery(pageRequest: IFundingDataVersionRequest): Observable<IFundingDataStagingTransactionInreviewResponse> {
    return this.post<IFundingDataStagingTransactionInreviewResponse>('/api/FundingData/stagingDataInReview/query', pageRequest);
  }

  public GetFundingStreamAndPeriodsFromStagingData(userId: number): Observable<IFundingStreamScope[]> {
    return this.get<IFundingStreamScope[]>('/api/FundingData/getFundingStreamsInfoForStaging', null, { userId: userId });
  }

  //schema audit log API's
  public GetFundingStreamAndPeriodsFromSchemaAudit(): Observable<IFundingStreamScope[]> {
    return this.get<IFundingStreamScope[]>('/api/SchemaAuditLog/getFundingStreamsInfo', null);
  }

  public GetSchemaInfoFromSchemaAudit(pageRequest: IFundingDataVersionRequest): Observable<ISchemaStagingModel[]> {
    return this.post<ISchemaStagingModel[]>('/api/SchemaAuditLog/getSchemaInfo', pageRequest);
  }

  public GetFundingStreamInfoForApprove(userId: number): Observable<IFundingStreamScope[]> {
    return this.get<IFundingStreamScope[]>('/api/FundingData/getFundingStreamInfoForApprove', null, { userId: userId });
  }

  public GetFundingStreamAndPeriodsFromCoreData(): Observable<IFundingStreamScope[]> {
    return this.get<IFundingStreamScope[]>('/api/FundingData/getFundingStreamsInfoForCore', null);
  }

  public GetSchemaInfoForStaging(pageRequest: IFundingDataVersionRequest): Observable<IFilterDataModel> {
    return this.post<IFilterDataModel>('/api/FundingData/getSchemaInfoForStaging', pageRequest);
  }

  public GetSchemaInfoForApproveFundingData(pageRequest: IFundingDataVersionRequest): Observable<ISchemaStagingModel[]> {
    return this.post<ISchemaStagingModel[]>('/api/FundingData/getSchemaInfoForApproveFundingData', pageRequest);
  }

  public GetSchemaInfoForCore(pageRequest: IFundingDataVersionRequest): Observable<ISchemaStagingModel[]> {
    return this.post<ISchemaStagingModel[]>('/api/FundingData/getSchemaInfoForCore', pageRequest)
      .pipe(retry(3));
  }

  public approveFundingData(data: IFundingTransactionModel): Observable<FundingDataApprove> {
    return this.post<FundingDataApprove>('/api/FundingDataApprove/approve', data, null, null, false);
  }
  public sendForReviewFundingData(data: FundingDataStagingTransaction): Observable<boolean> {
    return this.post<boolean>('/api/FundingDataTransaction/sendForReview', data, null, null, false);
  }
  public checkActiveInReviewFundingData(data: FundingDataStagingTransaction): Observable<WaitingForApproval> {
    return this.post<WaitingForApproval>('/api/FundingDataTransaction/checkActiveInReviewFundingData', data, null, null, false);
  }
  public removeDraftFundingData(transactionId: number): Observable<boolean> {
    return this.get<boolean>('/api/FundingDataApprove/removeDraftFundingData', null, { id: transactionId });
  }

  public saveFundingData(data: IFundingDataStagingModel): Observable<number> {
    return this.post<number>('/api/FundingData/saveFundingData', data, null, null, false);
  }

  public removeFundingAgreementData(data: IFundingDataRemoveModel): Observable<boolean> {
    return this.post<boolean>('/api/FundingData/removeFundingData', data, null, null, false);
  }

  downloadFundingDataHistoryofChanges(data: IFundingTransactionModel): Observable<Blob> {
    return this.httpClient.post(`${this.httpBaseUrl}/api/FundingData/download/`, data, { responseType: 'blob' })
  }

  downloadFundingData(data: IFundingTransactionModel): Observable<Blob> {
    return this.httpClient.post(`${this.httpBaseUrl}/api/FundingData/downloadFundingData/`, data, { responseType: 'blob' })
  }

  public loadHistory(body: ProviderHistoryQuery): Observable<[IProviderHistoryEvent]> {
    return this.post<[IProviderHistoryEvent]>('/api/FundingData/audit/query', body);
  }

  public getReferenceName(): Observable<ReferenceName[]> {
    return this.get<ReferenceName[]>('/api/FundingDataSecondary/getReferenceNames', null);
  }

  public getFundingDataActiveSessionsList(id: number): Observable<FundingDataStagingTransaction[]> {
    return this.get<FundingDataStagingTransaction[]>('/api/FundingData/stagingData/list', null, { userId: id });
  }

  public checkForLatestVersion(id: number): Observable<ISnapshotDetailsModel> {
    return this.get<ISnapshotDetailsModel>('/api/FundingData/getLatestFundingSnapshot', null, { transactionId: id });
  }

  public getUpdatedVersion(transactionId: number, userId: number): Observable<FundingDataSnapshotTransactionViewModel> {
    return this.get<FundingDataSnapshotTransactionViewModel>('/api/FundingDataTransaction/getUpdatedVersion', null, { transactionId: transactionId, userId: userId });
  }

  public updateFundingDataChangeNote(data: FundingDataChangeNote): Observable<boolean> {
    return this.post<boolean>('/api/FundingDataTransaction/changeNote', data, null, null, false);
  }

}
