import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BackNavigationService } from '../../services/back-navigation.service';
import { SessionsStoreUserInformationService } from '../../services/sessions-store-user-information.service';
import { IUserInformation } from '../../models/user-information';
import { environment } from '../../../environments/environment';

@Component({
  templateUrl: './view-my-permissions-page.component.html',
  styleUrls: ['./view-my-permissions-page.component.scss']
})
export class ViewMyPermissionsPageComponent {

  user$ = new BehaviorSubject<IUserInformation>(null);
  isAdmin: boolean = false;
  canProcess: boolean = false;
  canPublish: boolean = false;
  genericAccess: boolean = false;
  fundingAgreementData: boolean = false;
  schemaManagement: boolean = false;
  fundingAgreementDataDeleteAccess: boolean = false;
  fundingDataProcess: boolean = false;
  fundingDataPublish: boolean = false;
  reconciliation: boolean = false;
  adultAllocation: boolean = false;
  allowedFundingStreamCodes: string[] = [];

  constructor(private backService: BackNavigationService, private userInformationService: SessionsStoreUserInformationService) {
    this.user$.next(userInformationService.getUserInformation());
  }

  onBackClicked(): void {
    this.backService.back();
  }

  ngOnInit(): void {
    this.user$.subscribe(user => {
      this.isAdmin = user.group === environment.sysAdminGroup;
      this.canProcess = user.permissions.canProcess;
      this.canPublish = user.permissions.canPublish;
      this.genericAccess = user.permissions.genericAccess;
      this.fundingAgreementData = user.permissions.fundingAgreementData;
      this.schemaManagement = user.permissions.schemaManagement;
      this.fundingAgreementDataDeleteAccess = user.permissions.fundingAgreementDataDeleteAccess;
      this.fundingDataProcess = user.permissions.fundingDataProcess;
      this.fundingDataPublish = user.permissions.fundingDataPublish;
      this.reconciliation = user.permissions.reconciliation;
      this.adultAllocation = user.permissions.adultAllocation;
      this.allowedFundingStreamCodes = this.sortingInLine(user.permissions.allowedFundingStreamCodes);
    });
  }
  sortingInLine(streamCodes: string[]): string[] {
    let codes: string[] = [];
    streamCodes.sort();
    let lineString: string = "";
    let length = streamCodes.length;
    for (let i = 0; i < length; i++) {
      let compare: string = streamCodes[i];
      let firstCharOfCompare = compare.charAt(0);
      lineString = compare;
      for (let j = i + 1; j < length; j++) {
        let compareTo: string = streamCodes[j];
        let firstCharOfCompareTo = compareTo.charAt(0);
        if (firstCharOfCompare === firstCharOfCompareTo) {
          lineString = lineString + ', ' + compareTo;
        }
        else {
          i = j - 1;
          codes.push(lineString);
          lineString = "";
          break;
        }
      }
    }
    return codes;
  }
}
