import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IUserInformation } from 'src/app/models/user-information';
import { SessionsStoreUserInformationService } from 'src/app/services/sessions-store-user-information.service';

@Component({
  templateUrl: './manage-data.page.html',
  styleUrls: ['./manage-data.page.scss']
})
export class ManageDataPage implements OnInit {
  reconciliation: boolean = false;

  constructor(private userInformationService: SessionsStoreUserInformationService) { }

  ngOnInit(): void {
    this.reconciliation = this.userInformationService.hasReconciliationAccess();
  }

}
