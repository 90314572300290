export enum TransactionDataType {
  FDSData = 'FDSData',
  PreviousSnapshotData = 'PreviousSnapshotData'
}

export enum SnapshotSource {
  GIASData = 'GIASData',
  PreviousSnapshot = 'PreviousSnapshot'
}

export enum ProviderUpdateType {
  SCOPE = 'scope',
  DESCOPE = 'descope',
  AttributeUpdate = 'update'
}

export interface ISnapshotTransaction {
  snapshotTransactionId?: number;
  name?: string;
  description?: string;
  fundingStreamId?: number;
  fundingStreamCode?: string;
  fundingPeriodId?: number;
  fundingPeriodCode?: string;
  snapshotSource?: string;
  fdzProviderSnapshotId?: number;
  fdzProviderSnapshotName?: string;
  isShared?: boolean;
  created?: Date;
  createdBy?: string;
  fundingStreamName?: string;
  fundingPeriodName?: string;
  fdzSnapshotEnvironment?: string;
  updateType?: string;
}

export class SnapshotTransaction implements ISnapshotTransaction {
  snapshotTransactionId?: number;
  name?: string;
  description?: string;
  fundingStreamId?: number;
  fundingStreamCode?: string;
  fundingPeriodId?: number;
  fundingPeriodCode?: string;
  snapshotSource?: string;
  fdzProviderSnapshotId?: number;
  fdzProviderSnapshotName?: string;
  isShared?: boolean;
  created?: Date;
  createdBy?: string;
  fundingStreamName?: string;
  fundingPeriodName?: string;
  updateType?: string;
  fdzSnapshotEnvironment?: string;

  constructor(data?: ISnapshotTransaction) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
}
