import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { TreeSelectDropdownComponent } from './components/tree-select-dropdown/tree-select-dropdown.component';
import { MultiSelectDropdownComponent } from './components/multi-select-dropdown/multi-select-dropdown.component';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { PagingComponent } from './components/paging/paging.component';
import { CustomMatPaginatorIntl } from './components/paging/custom-mat-paginator-intl';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomPaginatorDirective } from './directives/custom-paginator.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { SortableListComponent } from './components/sortable-list/sortable-list.component';
import { DatePickerDirective } from './directives/date-picker.directive';
import { SearchFieldComponent } from './components/search-field/search-field.component';
import { MultiSelectFieldComponent } from './components/multi-select-field/multi-select-field.component';
import { TreeSelectFieldComponent } from './components/tree-select-field/tree-select-field.component';
import { RecordPagingComponent } from './components/record-paging/record-paging.component';
import { RouterModule } from '@angular/router';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { DateRangeFieldComponent } from './components/date-range-field/date-range-field.component';
import { MultiSelectionTextPipe } from './components/multi-select-field/multi-selection-text.pipe';
import { TreeSelectionTextPipe } from './components/tree-select-dropdown/tree-selection-text.pipe';
import { FundingFlagSelectComponent } from './components/funding-flag-select/funding-flag-select.component';
import { ProviderUkprnSearchComponent } from './components/provider-ukprn-search/provider-ukprn-search.component';
import { ProviderAttributeSelectComponent } from './components/provider-attribute-select/provider-attribute-select.component';
import { DataTabModule } from '../data-tab/data-tab.module';
import { RecordEditorModule } from '../record-editor/record-editor.module';
import { FundingProviderUkprnSearchComponent } from './components/funding-provider-ukprn-search/funding-provider-ukprn-search.component';
import { MultiSelectFieldSchemaComponent } from './components/multi-select-field-schema/multi-select-field-schema.component';
@NgModule({
  declarations: [
    ProgressBarComponent,
    MultiSelectDropdownComponent,
    TreeSelectDropdownComponent,
    PagingComponent,
    CustomPaginatorDirective,
    ClickOutsideDirective,
    DatePickerDirective,
    SortableListComponent,
    SearchFieldComponent,
    MultiSelectFieldComponent,
    TreeSelectFieldComponent,
    RecordPagingComponent,
    LoadingSpinnerComponent,
    DateRangeFieldComponent,
    MultiSelectionTextPipe,
    TreeSelectionTextPipe,
    FundingFlagSelectComponent,
    ProviderUkprnSearchComponent,
    FundingProviderUkprnSearchComponent,
    ProviderAttributeSelectComponent,
    MultiSelectFieldSchemaComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    RouterModule,
    DataTabModule,
    RecordEditorModule
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
  ],
  exports: [
    ProgressBarComponent,
    MultiSelectDropdownComponent,
    TreeSelectDropdownComponent,
    PagingComponent,
    CustomPaginatorDirective,
    ClickOutsideDirective,
    DatePickerDirective,
    SortableListComponent,
    SearchFieldComponent,
    MultiSelectFieldComponent,
    TreeSelectFieldComponent,
    RecordPagingComponent,
    LoadingSpinnerComponent,
    DateRangeFieldComponent,
    FundingFlagSelectComponent,
    ProviderUkprnSearchComponent,
    FundingProviderUkprnSearchComponent,
    ProviderAttributeSelectComponent,
    MultiSelectFieldSchemaComponent
  ],
})
export class SharedModule { }
