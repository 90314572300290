import { Component, Input, HostListener, ElementRef, Output, EventEmitter, ViewChild, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { IDropdownItem } from "../record-editor.model";

@Component({
  selector: "search-dropdown",
  templateUrl: "./search-dropdown.component.html",
  styleUrls: ["./search-dropdown.component.scss"],
})
export class SearchDropdown implements OnInit, OnChanges {
  isLoading = false;
  list = [];
  tempList = [];
  keyword: any;
  _name: any = 'name';
  _id: any = 'id';

  @ViewChild("input", { static: false }) input: ElementRef;
  @Input("disabled") disabled: boolean;
  @Input("secondCol") secondCol: IDropdownItem;
  @Input("items")
  set items(value) {
    this.list = value;
    this.tempList = value;
  }
  @Input("searchDictionary") searchDictionary = [];
  @Input() propertyValue: any;
  @Output() valueChanged = new EventEmitter();

  value: any = null;
  shown = false;

  constructor(private ele: ElementRef) {
    this.value = this.propertyValue;
  }

  ngOnInit() {
    this.value = this.propertyValue;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.value = this.propertyValue;
  }

  search(lookupValue: any) {
    this.isLoading = true;
    this.list = [];
    this.searchDictionary.map(lookupColumn => {
      this.getFilteredList(this.tempList, lookupColumn, lookupValue)
    })
    this.isLoading = false;
  }

  getFilteredList(arr: any[], key: any, value: any) {
    for (let i = 0, l = arr.length; i < l; i++) {
      if (arr[i][key] && arr[i][key]?.toLowerCase()?.indexOf(value?.toLowerCase()) !== -1) {
        if (!this.list.includes(arr[i])) {
          this.list.push(arr[i]);
        }
      }
    }
  }

  select(item: any) {
    this.value = item[this._name];
    this.shown = false;
    this.valueChanged.emit(item);
  }

  show() {
    this.keyword = '';
    this.list = this.tempList;
    this.shown = this.shown ? false : true;
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 200);
  }

  @HostListener("document:click", ["$event"])
  onClick(e: any) {
    if (!this.ele.nativeElement.contains(e.target)) {
      this.shown = false;
    }
  }
}
