import { Injectable } from '@angular/core';
import { IProviderTypeLink, ReferenceDataApiService } from './api/reference-data-api.service';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { FDSReferenceData } from '../models/fds-reference-data';
import { take } from 'rxjs/operators';
import { IFundingPeriodScope, IFundingStreamScope } from '../models/funding-flag-scope';
import { FundingStreamApiService } from './api/funding-stream-api.service';
import { FundingStreamType } from '../models/funding-stream-type';

@Injectable({
  providedIn: 'root'
})
export class ReferenceDataService {

  // Subject to trigger refresh of funding stream options with providers scoped
  _clearFundingStreamOptions$ = new Subject<void>();

  REF_DATA_STORAGE_KEY = 'referenceData';
  TYPE_LINKS_STORAGE_KEY = 'typeLinks';
  FUNDING_FLAG_STORAGE_KEY = 'fundingFlags';

  _providerReferenceData = new BehaviorSubject<FDSReferenceData[]>([]);
  _providerTypeLinks = new BehaviorSubject<IProviderTypeLink[]>([]);
  _fundingFlagOptions = new BehaviorSubject<IFundingStreamScope[]>([]);

  // When this emits 'true', all subscribers get the reference data they are subscribed to.
  _referenceDataLoaded = new BehaviorSubject<boolean>(false);

  constructor(private referenceDataApi: ReferenceDataApiService, private fundingStreamApi: FundingStreamApiService) {
    this.loadReferenceData();
  }

  private loadReferenceData(): void {
    // Try to load values from session storage.
    const refData: FDSReferenceData[] = JSON.parse(sessionStorage.getItem(this.REF_DATA_STORAGE_KEY));
    const typeLinks: IProviderTypeLink[] = JSON.parse(sessionStorage.getItem(this.TYPE_LINKS_STORAGE_KEY));
    const fundingFlags: IFundingStreamScope[] = JSON.parse(sessionStorage.getItem(this.FUNDING_FLAG_STORAGE_KEY));

    if (refData && typeLinks && fundingFlags) {
      this._providerReferenceData.next(refData);
      this._providerTypeLinks.next(typeLinks);
      this._fundingFlagOptions.next(fundingFlags);
      this._referenceDataLoaded.next(true);

      console.log('Provider reference data loaded form cache.');
      return;
    }

    forkJoin([
      this.referenceDataApi.providerReferenceData(),
      this.referenceDataApi.providerTypeLinks(),
      this.fundingStreamApi.fundingStreams(FundingStreamType.Pre16)
    ])
      .subscribe(([refData, typeLinks, fundingFlags]) => {
        this._providerReferenceData.next(refData);
        this._providerTypeLinks.next(typeLinks);

        const fundingFlagOptions = fundingFlags.map((fs) => {
          return {
            id: fs.id,
            code: fs.fundingStreamCode,
            name: fs.fundingStreamName,
            fundingPeriodsInScope: fs.fundingPeriods.map((fp) => {
              return {
                id: fp.id,
                code: fp.fundingPeriodCode,
                name: fp.fundingPeriodName,
              } as IFundingPeriodScope;
            }).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
          } as IFundingStreamScope;
        }).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        this._fundingFlagOptions.next(fundingFlagOptions);

        sessionStorage.setItem(this.REF_DATA_STORAGE_KEY, JSON.stringify(refData));
        sessionStorage.setItem(this.TYPE_LINKS_STORAGE_KEY, JSON.stringify(typeLinks));
        sessionStorage.setItem(this.FUNDING_FLAG_STORAGE_KEY, JSON.stringify(fundingFlagOptions));

        this._referenceDataLoaded.next(true);
      });
  }

  public getProviderReferenceData(): Observable<FDSReferenceData[]> {
    return this._providerReferenceData.asObservable().pipe(take(1));
  }

  public getProviderTypeLinks(): Observable<IProviderTypeLink[]> {
    return this._providerTypeLinks.asObservable().pipe(take(1));
  }

  public getAllFundingFlagOptions(): Observable<IFundingStreamScope[]> {
    return this._fundingFlagOptions.asObservable().pipe(take(1));
  }

  public getReferenceDataLoaded(): Observable<boolean> {
    return this._referenceDataLoaded.asObservable();
  }

  public getClearFundingStreamOptions(): Observable<void> {
    return this._clearFundingStreamOptions$.asObservable();
  }

  public triggerRefreshFundingStreamOptions(): void {
    this._clearFundingStreamOptions$.next();
  }
}
