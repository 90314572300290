import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

export interface Breadcrumb {
  label: string,
  url: string,
}

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  static readonly SHOW_BREADCRUMBS = 'showBreadcrumbs';
  showBreadcrumbs = true;
  routeBreadcrumbs: Breadcrumb[];
  customBreadcrumbs: Breadcrumb[] = [];
  showBreadcrumbsSubject: Subject<boolean> = new Subject<boolean>();
  breadcrumbsSubject: Subject<Breadcrumb[]> = new Subject<Breadcrumb[]>();

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.showBreadcrumbs = true;
        this.routeBreadcrumbs = this.createBreadcrumbs(
          this.activatedRoute.root,
          '',
          [{label: 'Funding data service', url: '/'}]
        );
        this.showBreadcrumbsSubject.next(this.showBreadcrumbs);
        this.customBreadcrumbs = [];
        this.breadcrumbsSubject.next([...this.routeBreadcrumbs, ...this.customBreadcrumbs]);
      });
  }

  public getBreadcrumbs(): Observable<Breadcrumb[]> {
    return this.breadcrumbsSubject.asObservable();
  }

  public getShowBreadcrumbs(): Observable<boolean> {
    return this.showBreadcrumbsSubject.asObservable();
  }

  public setCustomBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
    this.customBreadcrumbs = breadcrumbs;
    this.breadcrumbsSubject.next([...this.routeBreadcrumbs, ...this.customBreadcrumbs]);
  }

  public clearCustomBreadcrumbs(): void {
    this.customBreadcrumbs = [];
    this.breadcrumbsSubject.next([...this.routeBreadcrumbs, ...this.customBreadcrumbs]);
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    if (this.router.url === '/') {
      return breadcrumbs;
    }

    const breadcrumbChildren: ActivatedRoute[] = route.children;
    if (breadcrumbChildren.length === 0) {
      return breadcrumbs;
    }

    for (const breadcrumbChild of breadcrumbChildren) {
      const routeURL: string = breadcrumbChild.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = breadcrumbChild.snapshot.data[BreadcrumbsService.ROUTE_DATA_BREADCRUMB];
      if (label) {
        breadcrumbs.push({label: label, url: url});
      }

      const showBreadcrumbs = breadcrumbChild.snapshot.data[BreadcrumbsService.SHOW_BREADCRUMBS];
      if (showBreadcrumbs !== undefined && !showBreadcrumbs) {
        this.showBreadcrumbs = false;
      }

      return this.createBreadcrumbs(breadcrumbChild, url, breadcrumbs);
    }
  }
}
