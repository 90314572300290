import { Component } from '@angular/core';

@Component({
  selector: 'app-manage-adult-provider-data',
  templateUrl: './manage-adult-provider-data.component.html',
  styleUrl: './manage-adult-provider-data.component.scss'
})
export class ManageAdultProviderDataComponent {

}
