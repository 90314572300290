import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { FundingFlag } from 'src/app/models/funding-flag';
import { IProviderResponse, ProviderResponse } from 'src/app/models/provider';
import { ProviderApiService } from '../../services/api/provider-api.service';
import { Observable } from 'rxjs';
import { ReferenceDataService } from '../../services/reference-data.service';
import { map, take } from 'rxjs/operators';
import { IProviderTab, ReferenceColumnName } from '../../maintain-data/components/providers/provider-tab/provider-tab.model';
import { FDSReferenceData } from '../../models/fds-reference-data';
import { IBulkScopeProvider } from '../models/bulk-scope-provider';
import { FundingFlagApiService } from '../../services/api/funding-flag-api.service';
import { Router } from '@angular/router';
import { PaymentOrganisation } from 'src/app/models/payment-organisation';
import { Providerattribute } from 'src/app/models/provider-attribute';
import { ProviderattributeValue } from 'src/app/models/provider-attribute-value';
import { ISnapshotTransaction } from 'src/app/maintain-provider-attributes/models/provider-transaction-data.model';
import { SnapshotTransactionScope } from 'src/app/maintain-provider-attributes/models/provider-transaction-scope-data.model';
import { ProviderTansactionResponse } from 'src/app/models/providerTransaction';

export enum ScopeUpdateType {
  Scope = 'Scope',
  Descope = 'Descope'
}

@Injectable()
export class ProviderScopeUpdatesService {

  private _providersFound$ = new BehaviorSubject<IBulkScopeProvider[]>([]);
  private _fundingFlags$ = new BehaviorSubject<FundingFlag[]>([]);
  private _savingData$ = new BehaviorSubject<boolean>(false);
  private _serviceLoaded$ = new BehaviorSubject<boolean>(false);
  private _scopingType$ = new BehaviorSubject<ScopeUpdateType>(null);
  private _updateProviderAttribute$ = new BehaviorSubject<boolean>(false);
  private _providerAttributesValues$ = new BehaviorSubject<FDSReferenceData[]>([]);
  private _providerPaymentAttributesValues$ = new BehaviorSubject<PaymentOrganisation[]>([]);
  private _providerAttributesDateValues$ = new BehaviorSubject<Providerattribute[]>([]);
  private _providerAttributesSelectedValues$ = new BehaviorSubject<ProviderattributeValue[]>([]);
  private _providersFoundForAttributes$ = new BehaviorSubject<ProviderResponse[]>([]);
  private _providersFoundForTransactionAttributes$ = new BehaviorSubject<ProviderResponse[]>([]);
  private _referenceData: FDSReferenceData[] = [];
  private providers$: IBulkScopeProvider[] = [];

  constructor(
    private providerApiService: ProviderApiService,
    private referenceDataService: ReferenceDataService,
    private fundingFlagApi: FundingFlagApiService,
    private router: Router
  ) {

    // ToDo: remove nested subscriptions
    referenceDataService.getReferenceDataLoaded().pipe(take(1)).subscribe((dataLoaded) => {
      dataLoaded && referenceDataService.getProviderReferenceData()
        .pipe(
          map((data) => data.filter((entry) => entry.lookupName === ReferenceColumnName.providerType))
        ).subscribe((typeReferenceData) => {
          this._referenceData = typeReferenceData;
        });
    })
  }

  updateScopingType(scopeType: ScopeUpdateType): void {
    this._scopingType$.next(scopeType);
  }

  getScopeTypeValue(): ScopeUpdateType {
    return this._scopingType$.value;
  }

  updateServiceLoaded(): void {
    this._serviceLoaded$.next(true);
  }

  getServiceLoadedValue(): boolean {
    return this._serviceLoaded$.value;
  }

  getProvidersFound(): Observable<IBulkScopeProvider[]> {
    return this._providersFound$.asObservable();
  }

  getProvidersFoundValue(): IBulkScopeProvider[] {
    return this._providersFound$.value;
  }

  getProvidersFoundForAttributes(): Observable<ProviderResponse[]> {
    return this._providersFoundForAttributes$.asObservable();
  }

  getProvidersFoundForAttributesValue(): ProviderResponse[] {
    return this._providersFoundForAttributes$.value;
  }

  getTransactionProvidersFoundForAttributesValue(): ProviderTansactionResponse[] {
    return this._providersFoundForTransactionAttributes$.value;
  }

  getSelectedFundingFlags(): Observable<FundingFlag[]> {
    return this._fundingFlags$.asObservable();
  }

  getSelectedFundingFlagsValue(): FundingFlag[] {
    return this._fundingFlags$.value;
  }

  getSavingData(): Observable<boolean> {
    return this._savingData$.asObservable();
  }

  updateAttribute(): void {
    this._updateProviderAttribute$.next(true);
  }

  getupdateAttributeValue(): boolean {
    return this._updateProviderAttribute$.value;
  }

  public clearService(): void {
    this._serviceLoaded$.next(false);
    this._providersFound$.next([]);
    this._fundingFlags$.next([]);
    this._savingData$.next(false);
    this._scopingType$.next(null);
    this._updateProviderAttribute$.next(false);
    this._providerAttributesValues$.next([]);
    this._providerPaymentAttributesValues$.next([]);
    this._providerAttributesDateValues$.next([]);
    this._providerAttributesSelectedValues$.next([]);
  }

  public updateProviderAttributeValue(fDSReferenceData: FDSReferenceData[]) {
    this._providerAttributesValues$.next(fDSReferenceData);
  }

  getSelectedProviderAttributeValue(): FDSReferenceData[] {
    return this._providerAttributesValues$.value;
  }

  public updateProviderPaymentAttributeValue(paymentOrganisation: PaymentOrganisation[]) {
    this._providerPaymentAttributesValues$.next(paymentOrganisation);
  }

  getProviderPaymentAttributeValue(): PaymentOrganisation[] {
    return this._providerPaymentAttributesValues$.value;
  }

  public updateProviderAttributeDateValue(providerValue: Providerattribute[]) {
    this._providerAttributesDateValues$.next(providerValue);
  }

  getProviderAttributeDateValue(): Observable<Providerattribute[]> {
    return this._providerAttributesDateValues$.asObservable();
  }

  public updateProviderAttributeSelectedValue(providerValue: ProviderattributeValue[]) {
    this._providerAttributesSelectedValues$.next(providerValue);
  }

  getProviderAttributeSelectedValue(): Observable<ProviderattributeValue[]> {
    return this._providerAttributesSelectedValues$.asObservable();
  }

  public updateProvidersFound(providersFound: ProviderResponse[]): void {
    
    if (this._updateProviderAttribute$) {
      const providers = providersFound.map((provider) => {
        return provider;
      });
      this._providersFoundForAttributes$.next(providers);
    }
    const providers = providersFound.map((provider) => {
      // Including FDSUID values for possible implementation of filtering
      return {
        id: provider.id,
        name: provider.name,
        ukprn: provider.ukprn,
        urn: provider.urn,
        providerStatusFDSUID: provider.providerStatusFDSUID,
        providerTypeFDSUID: provider.providerTypeFDSUID,
        providerSubtypeFDSUID: provider.providerSubtypeFDSUID,
        displayType: this._referenceData.find((refData) => refData.fdsuid === provider.providerTypeFDSUID)?.name ?? '',
      } as IBulkScopeProvider;
    });
    this._providersFound$.next(providers);
  }

  public updateFundingFlags(fundingFlags: FundingFlag[]) {
    this._fundingFlags$.next(fundingFlags);
  }

  public saveFundingFlags(): void {
    this._savingData$.next(true);
    const saveFlags = [];
    this._providersFound$.value.forEach((provider) => {
      this._fundingFlags$.value.forEach((fundingFlag) => {
        saveFlags.push(
          new FundingFlag(
            {
              providerId: provider.id,
              fundingPeriodId: fundingFlag.fundingPeriodId,
              isDeleted: this.getScopeTypeValue() === ScopeUpdateType.Descope,
            }
          )
        );
      })
    });

    this.fundingFlagApi.saveFundingFlags(saveFlags).subscribe((result) => {
      result
        ? this.router.navigate(['/manage-provider-data/manage-core-data/bulk/scope-confirmation']).then(this.clearService)
        : this._savingData$.next(false);
    }, (error) => {
      this._savingData$.next(false);
    });
  }

  public saveFundingFlagsTransaction(transation: ISnapshotTransaction): void {
    this._savingData$.next(true);
    const saveFlags = [];
    this._providersFound$.value.forEach((provider) => {
      this._fundingFlags$.value.forEach((fundingFlag) => {
        saveFlags.push(
          new FundingFlag(
            {
              providerId: provider.id,
              fundingPeriodId: fundingFlag.fundingPeriodId,
              isDeleted: this.getScopeTypeValue() === ScopeUpdateType.Descope,
            }
          )
        );
      })
    });
    const snapshotTransactionScope = new SnapshotTransactionScope({
      fundingFlags: saveFlags,
      providerTransactionData: transation
    });
    this.fundingFlagApi.saveFundingFlagsTransaction(snapshotTransactionScope).subscribe((result) => {
      result
        ? this.router.navigate(['/manage-provider-data/share-data/update-and-share/scope-confirmation'],
          { state: { scopeCount: this._providersFound$.value.length } }).then(this.clearService)
        : this._savingData$.next(false);
    }, (error) => {
      this._savingData$.next(false);
    });
  }
  public descopingProviderTransaction(transation: ISnapshotTransaction, provider: IProviderTab): SnapshotTransactionScope {
    const saveFlags = [];
    this._fundingFlags$.value.forEach((fundingFlag) => {
      saveFlags.push(
        new FundingFlag(
          {
            providerId: provider.providerId,
            fundingPeriodId: fundingFlag.fundingPeriodId,
            isDeleted: true
          }
        )
      );
    });
    const snapshotTransactionScope = new SnapshotTransactionScope({
      fundingFlags: saveFlags,
      providerTransactionData: transation
    });
    return snapshotTransactionScope
  }
  public descopingBulkProviderTransaction(transation: ISnapshotTransaction, providers: Observable<IBulkScopeProvider[]>): SnapshotTransactionScope {
    this.providers$ = [];
    providers.subscribe((p) => {
      p.forEach(provider => {
        this.providers$.push(provider);
      })
    });
    const saveFlags = [];
    this._fundingFlags$.value.forEach((fundingFlag) => {
      this.providers$.forEach(provider => {
        saveFlags.push(
          new FundingFlag(
            {
              providerId: provider.id,
              fundingPeriodId: fundingFlag.fundingPeriodId,
              isDeleted: true
            }
          )
        );
      });
    });
    const snapshotTransactionScope = new SnapshotTransactionScope({
      fundingFlags: saveFlags,
      providerTransactionData: transation
    });
    return snapshotTransactionScope
  }

  public updateTransactionProvidersFound(providersFound: ProviderTansactionResponse[]): void {
    if (this._updateProviderAttribute$) {
      const providers = providersFound.map((provider) => {
        return provider;
      });
      this._providersFoundForTransactionAttributes$.next(providers);
    }
    const providers = providersFound.map((provider) => {
      // Including FDSUID values for possible implementation of filtering
      return {
        snapshotTransactionId: provider.snapshotTransactionId,
        transactionId: provider.transactionId,
        name: provider.name,
        ukprn: provider.ukprn,
        urn: provider.urn,
        providerStatusFDSUID: provider.providerStatusFDSUID,
        providerTypeFDSUID: provider.providerTypeFDSUID,
        providerSubtypeFDSUID: provider.providerSubtypeFDSUID,
        displayType: this._referenceData.find((refData) => refData.fdsuid === provider.providerTypeFDSUID)?.name ?? '',
      } as IBulkScopeProvider;
    });
    this._providersFound$.next(providers);
  }
}
