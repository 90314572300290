import { Component, OnInit } from '@angular/core';
import { SessionsStoreUserInformationService } from 'src/app/services/sessions-store-user-information.service';

@Component({
  selector: 'app-manage-provider',
  templateUrl: './manage-provider.component.html',
  styleUrls: ['./manage-provider.component.scss']
})
export class ManageProviderComponent implements OnInit {
  adultAllocationAccess: boolean = false;

  constructor(private userInformationService: SessionsStoreUserInformationService) { }

  ngOnInit(): void {
    this.adultAllocationAccess = this.userInformationService.hasAdultAllocationAccess();
  }

}
