import { Component } from '@angular/core';

@Component({
  selector: 'app-adult-provider-data-table',
  templateUrl: './adult-provider-data-table.component.html',
  styleUrl: './adult-provider-data-table.component.scss'
})
export class AdultProviderDataTableComponent {

}
